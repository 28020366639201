<!-- 运维管理 现场人员 电站责任人详情 -->
<template>
  <scroll-layout>
    <template slot="header">
      <div v-if="pageButtons.length > 0" class="button-wrap">
        <ykc-button
          v-for="(button, index) in pageButtons"
          :key="button.id || index"
          v-bind="button"
          @click="button.handleClick(button, $event)">
          {{ button.text }}
        </ykc-button>
      </div>
    </template>
    <div class="content">
      <ykc-detail-plus :labelWidth="80">
        <div slot="header" class="clearfix">
          <span class="title">责任人信息</span>
        </div>
        <ykc-detail-item-plus label="责任人">
          <span>{{ responsiblePerson }}</span>
        </ykc-detail-item-plus>
      </ykc-detail-plus>
      <ykc-table
        style="margin-top: 16px"
        ref="YkcTable"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons">
        <template #headerButtons>
          <ykc-button
            type="plain"
            v-rbac="'maintain:personnel:stationResponsible:detailexport'"
            @click="doExportFile2">
            导出
          </ykc-button>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="total"
          :current-page.sync="currentPage"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageSize"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
  </scroll-layout>
</template>

<script>
  import { omManagementApi } from '@/service/apis';
  import { offlineExport } from '@/utils';

  export default {
    name: 'stationResponsibleDetail',
    components: {},
    data() {
      return {
        tableData: [],
        responsiblePerson: '',
        tableColumns: [
          { label: '电站编码', prop: 'stationId' },
          { label: '电站名称', prop: 'stationName' },
          {
            label: '电站类型',
            prop: 'stationType',
            scopedSlots: {
              default: ({ row }) => {
                return <span>{row.stationType === 1 ? '直联' : '互联'}</span>;
              },
            },
          },
          { label: '归属商户', prop: 'operatorName' },
        ],
        tableTitle: '电站信息',
        tableOperateButtons: [],
        total: 0,
        currentPage: 1,
        pageSize: 10,
        formData: {},
        pageButtons: [
          {
            id: '1',
            text: '返回',
            type: 'plain',
            handleClick: () => {
              this.$router.go(-1);
            },
          },
        ],
      };
    },
    created() {
      this.responsiblePerson = this.$route.query.responsiblePerson;
    },
    mounted() {
      this.getDetailList();
    },
    methods: {
      getDetailList() {
        const reqParams = {
          responsiblePerson: this.responsiblePerson,
          current: this.currentPage,
          size: this.pageSize,
        };
        omManagementApi.stationResponsibleDetail(reqParams).then(res => {
          this.tableData = res?.records || [];
          this.total = res.total;
        });
      },
      // 导出 电站
      doExportFile2() {
        console.log('doExportFile2');
        offlineExport(
          {
            downloadType: 'responsible_information',
            jsonNode: {
              responsiblePerson: this.responsiblePerson,
            },
          },
          this.total
        );
      },

      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.currentPage = current;
        this.getDetailList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.currentPage = 1;
        this.pageSize = size;
        this.getDetailList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .content {
    .clearfix {
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
</style>
